import Constants from "expo-constants";
import { Platform } from "react-native";
import { set } from 'lodash';

const localhost = Platform.OS === "ios" ? "localhost:8080" : "10.0.2.2:8080";

const ENV = require("./env").default;

const getSettings = (env = Constants.manifest.releaseChannel) => {
  // What is __DEV__ ?
  // This variable is set to true when react-native is running in Dev mode.
  // __DEV__ is true when run locally, but false when published.
  if (__DEV__) {
    // set(ENV, 'dev.apiUrl', localhost);
    return ENV.dev;
  } else if (env === "default") {
    return ENV.dev;
  } else if (env === "prod") {
    return ENV.prod;
  } else {
    return ENV.prod;
  }
};

export default getSettings;